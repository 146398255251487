
      import {Router} from '@vaadin/router';
      import {
          gitHubAuthenticationProceed,
          isAuthenticated,
          isNotAuthenticated
      } from "@whilecat/services/authentication-service.ts";
      import {checkIfLevelAccessible} from "@whilecat/services/user-course-service.ts";
      import {redirectTo, routeTo404, routeToLogin} from "@whilecat/core/utils/location-utils.js";

      const outlet = document.getElementById('outlet');
      const router = new Router(outlet);
      router.setRoutes([
          {
              path: '/',
              component: 'landing-page',
              action: async () => await import('./components/pages/landing/landing.js'),
          },
          {
              path: '/login',
              component: 'login-form',
              action: async () => {
                  if (isAuthenticated()) {
                      return redirectTo("/courses");
                  }
                  await import('./components/pages/security/login-form.js')
              }
          },
          {
              path: '/registration',
              component: 'registration-form',
              action: async () => {
                  if (isAuthenticated()) {
                      return redirectTo("/courses");
                  }
                  await import('./components/pages/security/registration-form.js')
              }
          },
          {
              path: '/restore-password',
              component: 'forgot-password-form',
              action: async () => {
                  if (isAuthenticated()) {
                      return redirectTo("/courses");
                  }
                  await import('./components/pages/security/forgot-password-form.js')
              }
          },
          {
              path: '/reset-password',
              component: 'reset-password',
              action: async () => {
                  if (isAuthenticated()) {
                      return redirectTo("/courses");
                  }
                  await import('./components/pages/security/reset-password.js')
              }
          },
          {
              path: '/confirm',
              component: 'confirm-registration-form',
              action: async () => {
                  if (isAuthenticated()) {
                      return redirectTo("/courses");
                  }
                  await import('./components/pages/security/confirm-registration-form.js')
              }
          },
          {
              path: '/authentication/github',
              component: 'external-authentication-processing',
              action: async () => {
                  if (isAuthenticated()) {
                      return redirectTo("/courses");
                  }
                  await import('./components/pages/security/external-authentication-processing.js')
              }
          },
          {
              path: '/authentication/google',
              component: 'external-authentication-processing',
              action: async () => {
                  if (isAuthenticated()) {
                      return redirectTo("/courses");
                  }
                  await import('./components/pages/security/external-authentication-processing.js')
              }
          },
          {
              path: '/your-courses',
              component: 'user-course-list',
              action: async (commands) => {
                  if (isNotAuthenticated()) {
                      return routeToLogin(commands);
                  }
                  import('./components/pages/courses/user-course-list.js')
              },
          },
          {
              path: '/courses',
              component: 'course-list',
              action: async () => await import('./components/pages/courses/course-list.js'),
          },
          {
              path: '/prices',
              component: 'prices-block',
              action: async () => await import('./components/pages/prices/prices-block.js'),
          },
          {
              path: '/repetition',
              component: 'repetition-page',
              action: async (context, commands) => {
                  if (isNotAuthenticated()) {
                      return routeToLogin(commands);
                  }
                  await import('./components/pages/repetition/repetition.js')
              }
          },
          {
              path: '/courses/:section/:course',
              action: async (context, commands) => {
                  const course = context.params.course;
                  const section = context.params.section;
                  try {
                      await import(`./components/content/${section}/${course}/levels.js`);
                  } catch (error) {
                      console.error(error);
                      return routeTo404(commands);
                  }
                  return commands.component(`${section}-${course}-levels`);
              },
          },
          {
              path: '/courses/:section/:course/lesson-:lessonNumber',
              action: async (context, commands) => {
                  const courseName = context.params.course;
                  const section = context.params.section;
                  const lessonNumber = context.params.lessonNumber;

                  if (isNotAuthenticated()) {
                      return routeToLogin(commands);
                  }

                  const ext = courseName == 'shell' ? 'ts' : 'js';

                  const course = await import(`./components/content/${section}/${courseName}/levels.js`);                  
                  const lesson = await import(`./components/content/${section}/${courseName}/lesson-${lessonNumber}.${ext}`);

                  const accessible = await checkIfLevelAccessible(course.getCourseUuid(), lesson.getLevelUuid());
                  if (!accessible) {
                      return routeTo404(commands);
                  }

                  try {
                      await import(`./components/content/${section}/${courseName}/lesson-${lessonNumber}.${ext}`);
                  } catch (error) {
                      console.error(error);
                      return routeTo404(commands);
                  }
                  return commands.component(`${section}-${courseName}-lesson-${lessonNumber}`);
              },
          },
          {
              path: '/404',
              component: 'not-found',
              action: async (context) => {
                  console.error("Redirected to not found page: ", context)
                  await import('./components/pages/404/not-found.js')
              }
          },
          {
              path: '/profile',
              component: 'user-profile',
              action: async () => {
                  await import('./components/pages/user/user-profile.js')
              }
          },
          {
              path: '/level-viewer(.*)',
              component: 'level-viewer',
              action: async () => {
                  await import('./components/dev/level-viewer.js')
              }
          },
          {
              path: '(.*)',
              component: 'not-found',
              action: async (context, commands) => {
                  console.error("Not found page: ", context)
                  return routeTo404(commands);
              }
          },
      ])
  