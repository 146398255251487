import {css} from 'lit';
import '@whilecat/core/editors/code-area.js';
import '@whilecat/core/buttons/run-button.js';
import {BaseCodeTask} from "@whilecat/core/tasks/java/base-code-task.js";
import {CodeType} from "@whilecat/services/java-compiler-service.ts";

export class CodeEditorTask extends BaseCodeTask {
    static styles = [
        super.styles,
        css`
          p {
            word-wrap: break-word;
            //max-width: 600px;
          }

          .input-block {
            display: block;
            width: 100%;
          }

          .showAllCodeButton {
            position: absolute;
            top: 40px;
            right: 20px;
            border: none;
          }
        `,
    ];

    static properties = {
        data: {type: Object},
        error: {type: Boolean},
        outputAvailable: {type: Boolean},
        output: {type: String},
        onVerification: {type: Boolean},
    };

    /**
     * Constructor
     * @param codeType {CodeType}
     * @param isOneLineEditor {Boolean}
     */
    constructor(codeType, isOneLineEditor) {
        super(codeType === undefined ? CodeType.CLASS : codeType, false);
    }

    onViewFullCodeToggled(toggled) {
        const input = this.shadowRoot.querySelector("#user-input");

        if (toggled) {
            // show full code
            this.data.userCode = input.getCode();
            input.setCode(this.getFullCode());
            this.setInputEnabled(false, false);
        } else {
            input.setCode(this.data.userCode);
            this.setInputEnabled(true, false);
        }
    }

    checkAnswer(output) {
        for (const possibleAnswer of this.data.correctAnswer) {
            const regex = new RegExp(possibleAnswer);
            if (regex.test(output)) {
                return true;
            }
        }
        return false;
    }
}

customElements.define('code-editor-task', CodeEditorTask);
