import {css, html, LitElement} from 'lit';

export class BaseTask extends LitElement {
    static styles = css`
      .error-label {
        margin-bottom: 20px;
      }
    `;

    constructor() {
        super();

        document.addEventListener("keydown", (event) => {
            if (event.key === "m" && event.metaKey) {
                // FIXME: THIS IS A DEBUG FEATURE: SHOULD BE DISABLED IN PRODUCTION
                console.log("Meta + p: THIS IS A DEBUG FEATURE: SHOULD BE DISABLED IN PRODUCTION")
                this.showCorrectAnswer();
            }
        });
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.setAttribute('__catask__', 'true');
        
        if(this.getAttribute("completed") === 'true') {
            this.showCorrectAnswer();
        }
    }

    /**
     * @abstract
     * Should be implemented by child classes and verify provided answers
     */
    verify() {

    }

    /**
     * @abstract
     * Should be implemented by child classes and show the correct answer
     */
    showCorrectAnswer() {

    }

    /**
     * @abstract
     * Can be implemented by child classes to perform any necessary cleanup after the next
     * task button is pressed.
     */
    cleanup() {

    }

    _completeTask() {
        this.showCorrectAnswer();
        this.verify();
    }

    renderErrorText(isError, errorText) {
        return isError
            ? html`
                  <sl-alert class="error-label" variant="danger" open>
                    <sl-icon slot="icon" name="exclamation-circle"></sl-icon>
                    <strong>Incorrect Answer</strong><br/>
                    Please try again.
                    ${errorText}
                  </sl-alert>
            `
            : '';
    }
}
