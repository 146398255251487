import {requireNotNull} from "@whilecat/core/utils/validation.ts";
import type { paths } from "@whilecat/generated/openapi/java-compiler.ts"
import createClient from "openapi-fetch";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const client = createClient<paths>({ baseUrl: "/api", headers: {"TimeZone": timezone}});


export const CodeType = {
    CLASS: "CLASS",
    SCRIPT: "SCRIPT",
    TEST: "TEST",
} as const;

type CodeTypeT = keyof typeof CodeType;


export async function compileCode(codeSnippet: string, codeType: CodeTypeT) {
    requireNotNull(codeSnippet, "code can't be null")
    requireNotNull(codeType, "code type can't be null")

    const payload = {
        type: codeType,
        code: codeSnippet
    };
    const {response, data} = await client.POST("/java/run", {body: payload})

    return {response, data};
}